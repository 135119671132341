import React, { ReactNode } from "react";
import { Button as BootstrapButton } from "react-bootstrap";
import { Img } from "../Img";

import styles from "./Button.module.css";

export interface ButtonText {
  variant: string;
  size?: string;
  outlinedOnHover?: boolean;
  icon?: string;
  type?: string;
  disabled?: boolean;
  children?: ReactNode;
  onClick?: () => void;
}

export default function Button({
  variant,
  outlinedOnHover,
  type,
  disabled = false,
  icon,
  children,
  onClick,
}: ButtonText) {
  const setStyle = variant === "primary" ? styles.primary : styles.secondary;
  return (
    <BootstrapButton
      type={type || "button"}
      className={`${setStyle} ${outlinedOnHover ? styles.outlined : ""}`}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={styles.wrapper}>
        {icon && <Img src={icon} className={styles.img} />}
        {children}
      </div>
    </BootstrapButton>
  );
}
