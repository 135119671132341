import { Languages } from "../LanguageContext";

const translations = {
  [Languages.English]: {
    verifyLevel: "Placement",
    availableText: "We are available 5 days a week.",
    availableDetailsText: "Mon.-Fri.: 09:00-17:30",
    call: "Direct contact",
    or: "or",
    contactWithUs: "Message",
    back: "Back",
    websiteLanguage: "Website Language",
    purchaseNow: "Purchase now",
  },
  [Languages.German]: {
    verifyLevel: "Einstufung",
    availableText: "Wir sind 5 Tage die Woche erreichbar.",
    availableDetailsText: "Mo.-Fr.: 09:00-17:30",
    call: "Direkt kontaktieren",
    or: "or",
    contactWithUs: "Nachricht",
    back: "Zurück",
    websiteLanguage: "Sprache der Website",
    purchaseNow: "Direkt buchen",
  },
};

export { translations };
