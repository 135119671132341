import Link from "next/link";
import React, { useContext } from "react";
import { MenuItem } from "../../../services/wordpressApi";
import LanguageContext from "../../LanguageContext";
import { translations } from "../translations";
import { Img } from "../../elements/Img";

import styles from "./TopInfo.module.css";
import cx from "classnames";
import { Container } from "react-bootstrap";

export default function TopInfo() {
  const { language } = useContext(LanguageContext);

  return (
    <Container className={styles.containerWrapper}>
      <div className={styles.topInfo}>
        <div className={styles.leftColumn}>
          <p className={cx(styles.bold, styles.hideOnMobile)}>
            {translations[language].availableText}
          </p>
          <p>{translations[language].availableDetailsText}</p>
        </div>
        <div className={styles.rightColumn}>
          <p className={cx(styles.bold, styles.hideOnMobile)}>
            {translations[language].call}
          </p>
          <Link href="tel: 4312021858" passHref>
            <a className={cx(styles.bold, styles.topInfoLink)}>
              <Img src={require("../images/ico-phone.svg")} />
              <span>+43 1 202 18 58</span>
            </a>
          </Link>
          <p>{translations[language].or}</p>
          <Link
            href={
              language === "en"
                ? "/en/german-language-school-lab-vienna-contact-us"
                : "/kontakt-lcl-sprachschule-deutsch-sprachen-labor"
            }
            passHref
          >
            <a className={cx(styles.bold, styles.topInfoLink)}>
              <span>{translations[language].contactWithUs}</span>
              <Img src={require("../images/ico-right-arrow.svg")} />
            </a>
          </Link>
        </div>
      </div>
    </Container>
  );
}
