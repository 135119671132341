import React, { ReactElement, useContext, useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { MenuItem } from "../../services/wordpressApi";
import { urlReplace } from "../../services/urlReplace";
import Link from "next/link";
import { isMobile } from "react-device-detect";
import styles from "./Navigation.module.css";
import cx from "classnames";
import { translations } from "./translations";
import LanguageContext from "../LanguageContext";

interface Props {
  element: MenuItem;
  children?: any;
  nestedValue?: string;
}

export default function NavDropdownComponent({
  element,
  children,
  nestedValue = "2",
}: Props): ReactElement {
  const { language } = useContext(LanguageContext);
  const [show, setShow] = useState(false);
  const [key, setKey] = useState("");

  const showDropdown = () => {
    setShow(!show);
  };
  const hideDropdown = () => {
    setShow(false);
  };

  return (
    <NavDropdown
      title={
        <div className="navbar-1lvl-item">
          <Link href={urlReplace(element.url)}>
            <div className={styles.dropdownLinkWrapper}>
              <span dangerouslySetInnerHTML={{ __html: element.title }} />
              <div
                onClick={(e) => {
                  if (isMobile) {
                    e.preventDefault();
                    showDropdown();
                  }

                  e.stopPropagation();
                }}
                className={cx(styles.dropdownToggleAffter, {
                  [styles.dropdownToggleAffterShow]: show,
                })}
              />
            </div>
          </Link>
        </div>
      }
      id={`collasible-nav-dropdown-${element.title}`}
      key={`main-menu-dropdown-${element.title}-${key}`}
      className={`navbar-${nestedValue}lvl-menu`}
      rootCloseEvent={undefined}
      show={show}
      onMouseEnter={() => {
        if (!isMobile) {
          showDropdown();
        }
      }}
      onMouseLeave={() => {
        if (!isMobile) {
          setKey(String(Date.now()));
          hideDropdown();
        }
      }}
    >
      <div className={styles.mobileSubNavInfoWrapper}>
        <button className={styles.backButton} onClick={hideDropdown}>
          <span className={styles.backButtonArrow} />
          {translations[language].back}
        </button>
        <p
          className={styles.parentName}
          dangerouslySetInnerHTML={{ __html: element.title }}
        />
      </div>

      {element.children.map((child) => {
        if (child.children.length > 0) {
          return <NavDropdownComponent element={child} nestedValue="3" />;
        } else {
          return (
            <Link
              key={`main-menu-2lvl-link-item-${child.title}`}
              href={urlReplace(child.url)}
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={styles.dropdownLinkWrapper}
              >
                {child.title}
                {child.children.length > 0 && (
                  <div className={styles.dropdownToggleAffter} />
                )}
              </div>
            </Link>
          );
        }
      })}
    </NavDropdown>
  );
}
