import deFlag from "./images/de-flag.svg";
import enFlag from "./images/en-flag.svg";
import { Languages } from "../LanguageContext";

const languageToPath: Record<Languages, string> = {
  [Languages.German]: "",
  [Languages.English]: "en",
};

const languageToFlag: Record<Languages, SVGAElement> = {
  [Languages.German]: deFlag,
  [Languages.English]: enFlag,
};

const languageTR: Record<Languages, string> = {
  [Languages.German]: "Language:",
  [Languages.English]: "Language:",
};

const englishTR: Record<Languages, string> = {
  [Languages.German]: "Deutsch",
  [Languages.English]: "English",
};

const germanTR: Record<Languages, string> = {
  [Languages.German]: "Deutsch",
  [Languages.English]: "English",
};
const translations: Record<Languages, Record<Languages, string>> = {
  [Languages.German]: germanTR,
  [Languages.English]: englishTR,
};

export { languageTR, languageToPath, languageToFlag, translations };
