import { createContext, ReactNode } from "react";
enum Languages {
  English = "en",
  German = "de",
}

const LanguageContext = createContext({ language: Languages.German });

const LanguageContextProvider: React.FC<{
  lang: string;
  children: ReactNode;
}> = ({ lang, children }) => {
  const language = lang === "en" ? Languages.English : Languages.German;
  return (
    <LanguageContext.Provider value={{ language }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
export { LanguageContextProvider, Languages };
