import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MenuItem } from "../../services/wordpressApi";

import styles from "./Footer.module.css";
import { Img } from "../elements/Img";
import Link from "next/link";

import arrowSmall from "../elements/Card/assets/arrow.svg";
import { urlReplace } from "../../services/urlReplace";
import LanguageContext from "../LanguageContext";
import { translations } from "./translations";
import Logo from "../elements/Logo";

import rightElipse from "./assets/right-elipse.svg";
import SingleSocial from "../ContactUs/SingleSocial/SingleSocial";

export interface IFooterProps {
  footer1: MenuItem[];
  footer2: MenuItem[];
  footer3: MenuItem[];
  footer4: MenuItem[];
}

export default function Footer({
  footer1,
  footer2,
  footer3,
  footer4,
}: IFooterProps) {
  const { language } = useContext(LanguageContext);

  return (
    <>
      <footer className={styles.root}>
        <Img className={styles.rightElipse} src={rightElipse} />
        <Container>
          <Row className={styles.container}>
            <Col className={styles.column} md={3} sm>
              <div className={styles.logoComponent}>
                <Logo isWhite />
              </div>
              <div className={styles.firstColumnContent}>
                <div className={styles.placeInfo}>
                  <h6>LcL-Institut für Sprachen und Kommunikation GmbH</h6>
                  <h6>Spittelberggasse 22</h6>
                  <h6>1070 Wien</h6>
                </div>
                <div className={styles.contactInfo}>
                  <a href="tel: +43 1 202 18 58">+43 1 202 18 58</a>
                  <a href="mailto: team@lcl-institut.at">
                    team@lcl-institut.at
                  </a>
                </div>
                <div className={styles.socialsWrapper}>
                  <SingleSocial
                    socialIcon={{ url: require("./assets/TTB.svg"), alt: "" }}
                    socialLink="https://www.tiktok.com/@lclinstitut"
                  />
                  <SingleSocial
                    socialIcon={{ url: require("./assets/FBB.svg"), alt: "" }}
                    socialLink="https://www.facebook.com/lcl.institut/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_transition=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0"
                  />
                  <SingleSocial
                    socialIcon={{ url: require("./assets/LIB.svg"), alt: "" }}
                    socialLink="https://www.linkedin.com/company/lcl-institut-f%C3%BCr-sprachen-und-kommunikation-gmbh/"
                  />
                  <SingleSocial
                    socialIcon={{ url: require("./assets/IGB.svg"), alt: "" }}
                    socialLink="https://www.instagram.com/lclsprachinstitut/"
                  />
                </div>
                <div className={styles.contactLinks}>
                  {footer4.map((footerItem) => {
                    return (
                      <Link
                        href={urlReplace(footerItem.url)}
                        key={`menu-footer-link-${footerItem.title}`}
                        className={styles.contactLink}
                      >
                        <a>
                          {footerItem.title}
                          <Img src={arrowSmall} />
                        </a>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </Col>
            <Col className={styles.column} md={3} sm>
              <h6 className={styles.menuTitle}>Menu</h6>
              <div className={styles.firstMenuItemContainer}>
                {footer1.map((footerItem) => {
                  return (
                    <Link
                      href={urlReplace(footerItem.url)}
                      key={`menu-footer-link-${footerItem.title}`}
                    >
                      {footerItem.title}
                    </Link>
                  );
                })}
              </div>
            </Col>
            <Col className={styles.column} md={6} sm>
              <h6 className={styles.menuTitle}>
                {translations[language].ourOffer}
              </h6>
              <div className={styles.lastMenuItemContainer}>
                {footer2.map((footerItem) => {
                  return (
                    <Link
                      href={urlReplace(footerItem.url)}
                      key={`menu-footer-link-${footerItem.title}`}
                    >
                      {footerItem.title}
                    </Link>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className={styles.socialBar}>
        <Container className={styles.socialBarFooterContainer}>
          <div>
            <p>© 2022 LcL Institute Vienna</p>
          </div>
          <div>
            {footer3.map((footerItem) => {
              return (
                <Link
                  href={urlReplace(footerItem.url)}
                  key={`menu-footer-link-${footerItem.title}`}
                >
                  {footerItem.title}
                </Link>
              );
            })}
          </div>
        </Container>
      </div>
    </>
  );
}
