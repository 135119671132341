export function urlReplace(url: string): string {
  let [href, queries] = url.split("?");
  href = href.replace("https://lcl.codeholic.pl/", "/");
  href = href.replace("/de/", "/");

  const queryElements = new URLSearchParams(queries);

  if (queryElements.has("lang") && url.indexOf("/de/") == -1) {
    href = queryElements.get("lang")?.split("/")[0] + "/" + href;
  }
  if (!href.startsWith("/") && !href.startsWith("http")) {
    href = "/" + href;
  }
  if (!href.startsWith("http")) {
    href = href.replace("/de/", "/");

    href = href.replace("//", "/");
  }

  if (url.startsWith("#")) {
    return url;
  }

  return href;
}
