import { Languages } from "../LanguageContext";

const translations = {
  [Languages.English]: {
    ourOffer: "Our offer",
  },
  [Languages.German]: {
    ourOffer: "Kursangebote",
  },
};

export { translations };
