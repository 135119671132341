import React, { useContext, useMemo, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import {
  languageTR,
  languageToPath,
  translations,
  languageToFlag,
} from "./translations";
import styles from "./LanguageSwitch.module.css";
import Link from "next/link";
import { Img } from "../elements/Img";
import { isMobile } from "react-device-detect";
import { urlReplace } from "../../services/urlReplace";
import LanguageContext, { Languages } from "../LanguageContext";

import { translations as translationsNav } from "../Navigation/translations";
import Head from "next/head";

type LanguageProps = {
  translatedSlugs?: Record<string, string>;
};

const LanguageSwitch: React.FC<LanguageProps> = ({ translatedSlugs }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [key, setKey] = useState("");
  const { language } = useContext(LanguageContext);
  const toggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };
  const onMouseEnter = () => setIsOpen(true);
  const onMouseLeave = () => setIsOpen(false);
  const flag = useMemo(
    () => <Img className={styles.flagIcon} src={languageToFlag[language]} />,
    [language]
  );

  const getTranslatedLink = (optionLang: Languages) => {
    if (optionLang === language) {
      return "#";
    } else {
      return translatedSlugs?.[optionLang]
        ? urlReplace(translatedSlugs[optionLang])
        : `/${languageToPath[optionLang]}`;
    }
  };

  const getTranslatedHrefLink = (optionLang: Languages) => {
    if (optionLang === language) {
      return "https://lcl-institut.at/";
    } else {
      return translatedSlugs?.[optionLang]
        ? urlReplace(translatedSlugs[optionLang])
        : `https://lcl-institut.at/${languageToPath[optionLang]}`;
    }
  };

  return (
    <>
      <Head>
        {Object.values(Languages).map((languageOption) => (
          <link
            key={languageOption}
            rel="alternate"
            hrefLang={languageOption}
            href={getTranslatedHrefLink(languageOption)}
          />
        ))}
      </Head>
      <NavDropdown
        title={
          <div className="navbar-1lvl-item">
            <div className={styles.dropdownLinkWrapper}>
              {flag}{" "}
              <span className={styles.shortLanguage}>
                {language.toUpperCase()}
              </span>
            </div>
          </div>
        }
        onMouseOver={() => {
          if (!isMobile) {
            onMouseEnter();
          }
        }}
        onMouseLeave={() => {
          if (!isMobile) {
            setKey(String(Date.now()));
            onMouseLeave();
          }
        }}
        show={isOpen}
        onClick={(e: React.MouseEvent) => {
          if (isMobile) {
            toggle(e);
          }
        }}
        className={`navbar-2lvl-menu ${styles.languageNavbar}`}
        id={"language-switch-dropdown"}
        key={`language-switch-dropdown-${key}`}
      >
        <div className={styles.mobileSubNavInfoWrapper}>
          <button className={styles.backButton} onClick={toggle}>
            <span className={styles.backButtonArrow} />
            {translationsNav[language].back}
          </button>
          <p
            className={styles.parentName}
            dangerouslySetInnerHTML={{ __html: "Website Language" }}
          />
        </div>
        {Object.values(Languages).map((languageOption) => {
          return (
            <a href={getTranslatedLink(languageOption)} key={languageOption}>
              <div
                className={`dropdown-item navbar-2lvl-item ${styles.languageDropdownElem}`}
              >
                <Img
                  className={styles.flagIconDropdownElem}
                  src={languageToFlag[languageOption]}
                />
                {translations[language][languageOption]}
              </div>
            </a>
          );
        })}
      </NavDropdown>
    </>
  );
};

export default LanguageSwitch;
