import Link from "next/link";
import React, { useContext, useEffect } from "react";
import { SocialProps } from "../../../services/reducers/contactUs";
import { Img } from "../../elements/Img";

import styles from "./SingleSocial.module.css";

const SingleSocial = ({ socialIcon, socialLink }: SocialProps) => {
  return (
    <Link href={socialLink} passHref>
      <a className={styles.singleSocial} target="_blank">
        <Img src={socialIcon.url} />
      </a>
    </Link>
  );
};

export default SingleSocial;
