import React, { useContext } from "react";
import ENBlackLogo from "../icons/header-logo.svg";
import DEBlackLogo from "../icons/de-header-logo.svg";
import ENWhiteLogo from "../icons/footer-logo.svg";
import DEWhiteLogo from "../icons/de-footer-logo.svg";
import { Img } from "../../elements/Img";
import LanguageContext from "../../LanguageContext";

export interface ILogoProps {
  isWhite?: boolean;
  className?: string;
}

export default function Logo({ className, isWhite = false }: ILogoProps) {
  const { language } = useContext(LanguageContext);

  const getLogotype = () => {
    switch (true) {
      case language === "de" && isWhite:
        return DEWhiteLogo;
      case language === "en" && isWhite:
        return ENWhiteLogo;
      case language === "de" && !isWhite:
        return DEBlackLogo;
      case language === "en" && !isWhite:
        return ENBlackLogo;
    }
  };

  return (
    <div>
      <Img
        className={className}
        src={getLogotype()}
        noLazyLoad={true}
        alt="HeaderLogo"
      />
    </div>
  );
}
